import React, { useEffect, useState } from "react";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import Avatar from "../../components/UI/atoms/Avatar";
import UserHeader from "../../components/UI/molecules/UserHeader";
import styles from "./Guardian.module.scss";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import ManageProfile from "../../components/organisms/ManageProfile";
import { Helmet } from "react-helmet";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { grades, mobileBreakPoint } from "../../constants/values";
import { RootState } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import WelcomeImage from "../../assets/images/welcomeImage.svg";
import Text from "../../components/UI/atoms/Text";
import Button from "../../components/UI/atoms/Button";
import { createSearchParams, useNavigate } from "react-router-dom";
import { updateUserRole } from "../../redux/features/auth/authSlice";
import { useGetProfilesQuery } from "../../redux/features/profile/profileApiSlice";
import Tag from "../../components/UI/atoms/Tag";
import { colors } from "../../sdk/colors";
import { ReactComponent as Dropdown } from "../../assets/icons/dropdown.svg";
import Star from "../../assets/icons/Star";
import { ReactComponent as EnglishIcon } from "../../assets/icons/englishIcon.svg";
import { ReactComponent as MathIcon } from "../../assets/icons/mathIcon.svg";
import { ReactComponent as ScienceIcon } from "../../assets/icons/scienceIcon.svg";
import { ReactComponent as PurchaseProfileImage } from "../../assets/images/purchaseProfileImage.svg";
import { ReactComponent as BigPurchaseProfileImage } from "../../assets/images/BigPurchaseProfileImage.svg";
import ProfileAvatar from "../../components/UI/atoms/ProfileAvatar";
import { ReactComponent as CreditCardIcon } from "../../assets/icons/creditCardIcon.svg";
import Subject from "../../components/organisms/Subject";
import { ReactComponent as PartyPopper } from "../../assets/icons/partyPopper.svg";
import { ReactComponent as SmallPartyPopper } from "../../assets/icons/smallPartyPopper.svg";
import GuardianHeader from "../../components/UI/molecules/GuardianHeader";
import ViewTopics from "../../components/organisms/ViewTopics";

function Guardian() {
  const navigate = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.authToken);

  const [gradeValue, setGradeValue] = useState<{
    value: number;
    label: string;
  }>({ value: 1, label: "Primary 1" });

  const { data: user, isLoading } = useGetUserQuery(undefined);

  const { data: profiles } = useGetProfilesQuery(undefined);

  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useAppDispatch();

  const handleNavigateToSubject = (subjectId: number) => {
    navigate({
      pathname: `subject/${subjectId}`,
      search: createSearchParams({
        grade: `${gradeValue.value}`,
      }).toString(),
    });
  };

  useEffect(() => {
    dispatch(updateUserRole("guardian"));
    console.log("mounting guardian");
  }, []);

  const {
    isOpen: isUpgradeProfileModalOpen,
    onOpen: onUpgradeProfileOpen,
    onClose: onUpgradeProfileModalClose,
  } = useDisclosure();

  const {
    isOpen: isProfileLimitModalOpen,
    onOpen: onProfileLimitOpen,
    onClose: onProfileLimitClose,
  } = useDisclosure();

  const {
    isOpen: isGradeOpen,
    onOpen: onGradeOpen,
    onClose: onGradeClose,
  } = useDisclosure();

  const handleCreateProfile = () => {
    if (!!profiles) {
      if (profiles?.length < 3) {
        navigate("create-profile");
      } else {
        onProfileLimitOpen();
      }
    }
  };

  const unpaidProfiles = profiles?.filter(
    (item) => item.status === ("pending" || "inactive")
  );

  return (
    <>
      <div className={styles.Guardian}>
        <Helmet>
          <title>Guardian - UBELA</title>
        </Helmet>
        <GuardianHeader onClick={onOpen} />
        <div className={styles.Container}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <div className={styles.CreateProfilePrompt}>
              <div className={styles.CreateProfilePromptWrapper}>
                <Text
                  variant={isMobileBreakpoint ? "heading 5" : "heading 3"}
                  fontWeight="medium"
                  style={{ textAlign: "start" }}
                  color="#fff"
                >
                  Set up a student profile. Get a subscription plan!
                </Text>
                <Button
                  disabled={!profiles}
                  onClick={handleCreateProfile}
                  variant="white"
                  buttonStyles={{
                    width: "200px",
                    fontSize: isMobileBreakpoint ? "10px" : "20px",
                  }}
                >
                  Create Profile
                </Button>
              </div>
              <img
                style={{ height: isMobileBreakpoint ? "140px" : "270px" }}
                src={WelcomeImage}
                alt=""
              />
            </div>
            <div className={styles.Share}>
              {isMobileBreakpoint ? <SmallPartyPopper /> : <PartyPopper />}
              <Text
                style={{
                  fontSize: isMobileBreakpoint ? 12 : 25,
                }}
              >
                Share app and win cool gifts
              </Text>
              <button
                onClick={() => navigate("share")}
                className={styles.ShareButton}
              >
                <Text
                  style={{
                    fontSize: isMobileBreakpoint ? 12 : 20,
                    textAlign: "center",
                  }}
                >
                  Share app
                </Text>
              </button>
            </div>
            <div className={styles.SubjectsWrapper}>
              <div className={styles.SubjectsHeader}>
                <Text>
                  Enjoy{" "}
                  <span style={{ fontWeight: 500, color: colors.primary }}>
                    Free
                  </span>{" "}
                  Lesson Videos
                </Text>
                <Tag onClick={onGradeOpen}>
                  <Text
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {gradeValue.label}
                  </Text>
                  <Dropdown />
                </Tag>
              </div>
              <div className={styles.Subjects}>
                <button
                  onClick={() => handleNavigateToSubject(1)}
                  className={styles.Subject}
                >
                  <EnglishIcon />
                  <Text>English</Text>
                </button>
                <button
                  onClick={() => handleNavigateToSubject(2)}
                  className={styles.Subject}
                >
                  <MathIcon />
                  <Text>Mathematics</Text>
                </button>
                <button
                  onClick={() => handleNavigateToSubject(3)}
                  className={styles.Subject}
                >
                  <ScienceIcon />
                  <Text>Science</Text>
                </button>
              </div>
            </div>
            <div className={styles.PurchaseProfile}>
              {isMobileBreakpoint ? (
                <PurchaseProfileImage
                  style={{ position: "absolute", right: 0 }}
                />
              ) : (
                <BigPurchaseProfileImage
                  style={{
                    position: "absolute",
                    right: 0,
                    top: -30,
                    zIndex: 0,
                  }}
                />
              )}
              <div className={styles.PurchaseProfileWrapper}>
                <Text
                  variant={isMobileBreakpoint ? "heading 5" : "heading 3"}
                  fontWeight="medium"
                  style={{ textAlign: "start" }}
                >
                  Get a Plan
                </Text>
                <Text
                  variant={isMobileBreakpoint ? "heading 5" : "heading 3"}
                  fontWeight="medium"
                  color={colors.primary}
                  style={{ textAlign: "start" }}
                >
                  Unlock all exciting features
                </Text>
              </div>
              <Button
                buttonStyles={{
                  width: isMobileBreakpoint ? "150px" : "300px",
                  fontSize: isMobileBreakpoint ? "10px" : "20px",
                  zIndex: 10,
                }}
                variant="white"
                disabled={!profiles}
                onClick={onUpgradeProfileOpen}
              >
                Pay For Subscription
              </Button>
            </div>
          </div>
          <Subject />
        </div>
      </div>
      <Drawer
        size={isMobileBreakpoint ? "sm" : "md"}
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <ManageProfile onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Modal isOpen={isProfileLimitModalOpen} onClose={onProfileLimitClose}>
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ProfileLimitModal}>
            <Text style={{ textAlign: "center" }}>
              Your profile creation limit has been reached.
            </Text>
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isGradeOpen} onClose={onGradeClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {grades.map((grade, index) => (
              <button
                onClick={() => {
                  setGradeValue({ value: grade.value, label: grade.label });
                  onGradeClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Star color={grade.color} />
                <Text>{grade.label}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isUpgradeProfileModalOpen}
        onClose={onUpgradeProfileModalClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ManageProfileModal}>
            <Text>Upgrade Profile</Text>
            <div className={styles.ProfilesAvatarsList}>
              {unpaidProfiles && unpaidProfiles?.length > 0 ? (
                unpaidProfiles.map((profile, index) => (
                  <div
                    key={profile.id}
                    className={styles.ManageProfileModalItem}
                  >
                    <button
                      onClick={() => {
                        onUpgradeProfileModalClose();
                        onClose();
                        navigate(`/app/guardian/manage-profiles/${profile.id}`);
                      }}
                      className={styles.ManageProfileItemAvatarWrapper}
                    >
                      <CreditCardIcon style={{ position: "absolute" }} />
                      <ProfileAvatar
                        variant={index === 1 ? "curve" : "default"}
                        image={`${profile?.avatar?.url}`}
                        isEdit
                        isLoading={isLoading}
                        index={index}
                      />
                    </button>
                    <Text
                      style={{
                        fontSize: 16,
                        textAlign: "center",
                        padding: "20px 0px",
                        width: "100px",
                      }}
                    >{`${profile.firstName} ${profile.lastName}`}</Text>
                  </div>
                ))
              ) : (
                <div className={styles.EmptyUnpaidProfilesMessage}>
                  <Text style={{ textAlign: "center" }}>
                    You have no profiles that need to be upgraded
                  </Text>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "30px" }}>
                    <Button
                      disabled={!profiles}
                      onClick={handleCreateProfile}
                      variant="white"
                      buttonStyles={{
                        width: "200px",
                        fontSize: isMobileBreakpoint ? "10px" : "20px",
                      }}
                    >
                      Create Profile
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Guardian;
