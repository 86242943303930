import React, { useEffect, useState } from "react";
import {
  useGetChampionshipQuestionsQuery,
  useGetChampionshipsQuery,
} from "../../redux/features/championship/championshipApiSlice";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import { championshipHelper } from "../../utils/championshipHelper";
import { ReactComponent as RestrictAccessImage } from "../../assets/images/restrictAccessImage.svg";
import styles from "./Quest.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import { useNavigate } from "react-router-dom";
import Questions from "../../components/organisms/Questions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import Text from "../../components/UI/atoms/Text";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import Button from "../../components/UI/atoms/Button";

function Quest() {
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );

  const { data: user } = useGetUserQuery(undefined, {
    refetchOnReconnect: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useAppDispatch();

  const profileId = useAppSelector(
    (state: RootState) => state.auth.activeProfileId
  );

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [questionIndex, setQuestionIndex] = useState<number>(1);

  useEffect(() => {
    if ((questionIndex + 1) % 6 === 0) {
      setPageNumber((prevValue) => prevValue + 1);
      console.log("running", questionIndex)
    }

    if (!user?.subscription && questionIndex >= 5) {
      onOpen();
    }
  }, [questionIndex]);
  const { data: championshipsData } = useGetChampionshipsQuery(undefined);
  const type = championshipHelper.getUserType(
    Number(user?.grade),
    championshipsData?.data
  );
  const { data, isSuccess, isFetching } = useGetChampionshipQuestionsQuery(
    { type, limit: 7, page: pageNumber },
    { refetchOnMountOrArgChange: true }
  );
  console.log(data, questionIndex, "questions", pageNumber);
  const navigate = useNavigate();
  const [result, setResult] = useState<string>("");

  return (
    <>
      <div className={styles.Quest}>
        <PageHeader
          pageTitle="Championship"
          handleGoBack={() => navigate(-1)}
        />
        <div className={styles.Container}>
          {isSuccess && (
            <Questions
              variant="championship"
              questions={[...data?.data].reverse()}
              onQuestionChange={(result) => setResult(result)}
              onIndexChange={(index) => setQuestionIndex(index)}
            />
          )}
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={() => undefined}>
        <ModalOverlay />
        <ModalContent borderRadius="10px" marginLeft="20px" marginRight="20px">
          <div className={styles.RestrictAccessModal}>
            <RestrictAccessImage width={"70%"} />
            <div className={styles.RestrictAccessModalContentWrapper}>
              <Text style={{ textAlign: "center" }}>
                Pay for a subscription to continue
              </Text>
              <Button
                onClick={() => {
                  navigate(
                    `/app/guardian/manage-profiles/${user?.id || profileId}`
                  );
                }}
                variant="white"
              >
                Pay for subscription
              </Button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Quest;
