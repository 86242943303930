import React from "react";
import styles from "./UserHeader.module.scss";
import Avatar from "../../atoms/Avatar";
import { ReactComponent as NotificationIcon } from "../../../../assets/icons/notificationIcon.svg";
import { useGetUserQuery } from "../../../../redux/features/user/userApiSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { mobileBreakPoint } from "../../../../constants/values";

function UserHeader({
  onClickAvatar,
  isGuardian = false,
}: {
  onClickAvatar?: () => void;
  isGuardian?: boolean;
}) {
  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;

  const profileToken = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  const token = useAppSelector((state: RootState) => state.auth.authToken);
  const { data: user, isLoading } = useGetUserQuery(
    isGuardian ? `${token}` : `${profileToken}`,
    { refetchOnMountOrArgChange: true }
  );
  return (
    <div className={styles.UserHeader}>
      <button
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
        onClick={onClickAvatar}
      >
        <img style={{height: isMobileBreakpoint ? 50 : 70, width: isMobileBreakpoint ? 50 : 70}} src={user?.avatar?.url} alt="" className={styles.Avatar} />
        <DropdownIcon />
      </button>
    </div>
  );
}

export default UserHeader;
