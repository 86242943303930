import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Payment.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import PaymentImage from "../../assets/images/paymentImage.svg";
import CreditCardIcon from "../../assets/icons/creditCardIcon.svg";
import GiftCardIcon from "../../assets/icons/giftCardIcon.svg";
import Text from "../../components/UI/atoms/Text";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import OTPInput from "../../components/UI/molecules/OTPInput";
import Button from "../../components/UI/atoms/Button";
import {
  useGetPaymentKeyQuery,
  usePayWithVoucherMutation,
  usePaymentMutation,
} from "../../redux/features/subscription/subscriptionApiSlice";
import {
  CreateProfileBody,
  PayWithVoucherBody,
  PaymentBody,
} from "../../models/profile";
import {
  useCreateProfileMutation,
  useSwitchProfileMutation,
} from "../../redux/features/profile/profileApiSlice";
import { useAppDispatch } from "../../redux/hooks";
import LoaderContext from "../../context/loader.context";
import {
  saveProfileId,
  setProfileTokens,
  updateUserRole,
} from "../../redux/features/auth/authSlice";
import { ErrorResponse } from "../../models/utilityTypes";
import { useToast } from "../../context/toast.context";
import { usePaystackPayment } from "react-paystack";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";

function Payment() {
  const { data: user } = useGetUserQuery(undefined);

  const { data: paymentKeys } = useGetPaymentKeyQuery(undefined);

  console.log(paymentKeys);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const [pay, { isLoading: isPaymentLoading }] = usePaymentMutation();

  const [payWithVoucher, { isLoading: isPayWithVoucherLoading }] =
    usePayWithVoucherMutation();

  const [createProfile, { isLoading: isCreateProfileLoading }] =
    useCreateProfileMutation();

  const [switchProfile, { isLoading: isSwitchProfileLoading }] =
    useSwitchProfileMutation();

  const { setLoader: setLoading } = useContext(LoaderContext);
  const { show } = useToast();
  const initializePayment = usePaystackPayment({
    publicKey: "pk_test_bc63d46cd83986a653f5e0d8fcfd6792cdf65d9d",
  });

  console.log(params.get("type"));

  const [code, setCode] = useState<string>("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePayment = async (profileId: number) => {
    const submitValues: PaymentBody = {
      profileId,
      planId: params.get("plan") || "",
      grade: Number(params.get("grade")),
    };
    console.log(submitValues);
    try {
      setLoading(true);
      const response = await pay(submitValues).unwrap();
      setLoading(false);
      const onSuccess = (reference: string) => {
        console.log(reference);
        handleSwitchProfile(response.data.profileId);
      };

      const onClose = () => {
        console.log("closed", user);
      };
      // window.location.assign(response.data.meta.authorization_url);
      console.log(response);
      initializePayment({
        onSuccess,
        onClose,
        config: {
          amount: Number(Number(response.data.amount) * 100),
          email: user?.email || "",
          reference: response.data.reference,
          metadata: {
            custom_fields: [
              {
                variable_name: "profileId",
                display_name: "Profile Id",
                value: response.data.profileId,
              },
              {
                variable_name: "planId",
                display_name: "Plan Id",
                value: response.data.planId,
              },
            ],
          },
        },
      });
      console.log(response, "payment");
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handlePayWithVoucher = async (profileId: number) => {
    const submitValues: PayWithVoucherBody = {
      profileId,
      planId: params.get("plan") || "",
      voucherCode: code,
      grade: Number(params.get("grade")),
    };
    onClose();
    try {
      setLoading(true);
      const response = await payWithVoucher(submitValues).unwrap();
      setLoading(false);
      console.log(response);
      handleSwitchProfile(Number(profileId));
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error?.data?.message}`, variant: "error" });
    }
  };

  const handleSwitchProfile = async (id: number) => {
    onClose();
    try {
      setLoading(true);
      const response = await switchProfile(id).unwrap();
      setLoading(false);
      console.log(response);
      // show({ message: "ldsldkld", variant: "success" });
      dispatch(setProfileTokens(response.token));
      dispatch(updateUserRole("child"));
      dispatch(saveProfileId(id));
      if (params.get("type") === "profile-creation") {
        show({ message: `Profile successfully created`, variant: "success" });
      } else {
        show({ message: `Successfully updated plan`, variant: "success" });
      }
      navigate("/app/learn", { replace: true });
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  const handleCreateProfile = async () => {
    const submitValues: CreateProfileBody = {
      firstName: params.get("firstName") || "",
      lastName: params.get("lastName") || "",
      grade: Number(params.get("grade")),
    };
    try {
      setLoading(true);
      const response = await createProfile(submitValues).unwrap();
      setLoading(false);
      console.log(response, "create profile");
      return response;
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  const handleCreateProfileWithVoucher = async () => {
    handleCreateProfile()
      .then((response) => {
        handlePayWithVoucher(Number(response?.data.id));
      })
      .catch((e) => console.log(e));
  };

  const handleCreateProfileWithGeneratePay = async () => {
    handleCreateProfile()
      .then((response) => {
        handlePayment(Number(response?.data.id));
      })
      .catch((e) => console.log(e));
  };

  const handlePayFlow = () => {
    if (params.get("type") === "profile-creation") {
      handleCreateProfileWithGeneratePay();
    } else if (params.get("type") === "subscription-renewal") {
      handlePayment(Number(params.get("profileId")));
    }
  };
  const handleVoucherFlow = () => {
    if (params.get("type") === "profile-creation") {
      handleCreateProfileWithVoucher();
    } else if (params.get("type") === "subscription-renewal") {
      handlePayWithVoucher(Number(params.get("profileId")));
    }
  };

  return (
    <div className={styles.Payment}>
      <PageHeader pageTitle="Make Payment" />
      <div className={styles.Container}>
        <div className={styles.ImageWrapper}>
          <img src={PaymentImage} alt="" />
        </div>
        <div className={styles.Wrapper}>
          <button
            onClick={handlePayFlow}
            className={styles.PaymentItem}
            id={styles.active}
          >
            <Text color="#fff">Debit/Credit Card</Text>
            <img src={CreditCardIcon} alt="" />
          </button>
          <button onClick={onOpen} className={styles.PaymentItem}>
            <Text>Pay with Voucher</Text>
            <img src={GiftCardIcon} alt="" />
          </button>
        </div>
      </div>
      <Drawer placement="bottom" isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <div className={styles.PayWithVoucher}>
            <PageHeader pageTitle="Make Payment" handleGoBack={onClose} />
            <div className={styles.Container}>
              <Text fontWeight="light" variant="heading 4">
                Pay with voucher card
              </Text>
              <div className={styles.VoucherInput}>
                <Text style={{ marginBottom: "10px" }} variant="heading 5">
                  Voucher Code
                </Text>
                <OTPInput isAlphaNumeric setOTPCode={setCode} otpCode={code} />
              </div>
              <Button
                isLoading={isCreateProfileLoading || isPayWithVoucherLoading}
                disabled={!(code.length === 6)}
                onClick={handleVoucherFlow}
              >
                Validate
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default Payment;
