import React, { useContext } from "react";
import styles from "./ManageProfile.module.scss";
import PageHeader from "../../UI/molecules/PageHeader";
import {
  useGetProfilesQuery,
  useSwitchProfileMutation,
} from "../../../redux/features/profile/profileApiSlice";
import { ReactComponent as AddProfile } from "../../../assets/icons/addProfile.svg";
import ProfileAvatar from "../../UI/atoms/ProfileAvatar";
import Text from "../../UI/atoms/Text";
import { ReactComponent as ProfileNotificationsIcon } from "../../../assets/icons/profileNotificationsIcon.svg";
import { ReactComponent as ProfileAnalysisIcon } from "../../../assets/icons/profileAnalysisIcon.svg";
import { ReactComponent as ProfileAccountIcon } from "../../../assets/icons/profileAccountIcon.svg";
import { ReactComponent as ProfileHelpIcon } from "../../../assets/icons/profileHelpIcon.svg";
import { ReactComponent as ProfileShareIcon } from "../../../assets/icons/profileShareIcon.svg";
import { ReactComponent as ProfileTermsAndConditionsIcon } from "../../../assets/icons/profileTermsAndConditionsIcon.svg";
import logoutImage from "../../../assets/images/signOutImage.svg";
import { ReactComponent as ProfileLogoutIcon } from "../../../assets/icons/profileLogoutIcon.svg";
import { ReactComponent as ProfileDevicesIcon } from "../../../assets/icons/profileDevicesIcon.svg";
import { ReactComponent as ProfilePrivacyIcon } from "../../../assets/icons/profilePrivacyIcon.svg";
import ProfileItem from "../../UI/molecules/ProfileItem";
import LoaderContext from "../../../context/loader.context";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  handleLogout,
  saveProfileId,
  setProfileTokens,
  tokenReceived,
  updateUserRole,
} from "../../../redux/features/auth/authSlice";
import { useLogoutMutation } from "../../../redux/features/auth/authApiSlice";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { MetaData } from "../../../models/auth";
import { osName } from "react-device-detect";
import { RootState } from "../../../redux/store";
import { colors } from "../../../sdk/colors";
import Button from "../../UI/atoms/Button";
import { ReactComponent as EditIcon } from "../../../assets/icons/editProfileIcon.svg";
import { ReactComponent as ProfilePayForSubscriptionIcon } from "../../../assets/icons/Bold/Shopping, Ecommerce/Cart Large 2.svg";
import { ReactComponent as CreditCardIcon } from "../../../assets/icons/creditCardIcon.svg";
import { useToast } from "../../../context/toast.context";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { mobileBreakPoint } from "../../../constants/values";

function ManageProfile({ onClose }: { onClose: () => void }) {
  const { setLoader: setLoading } = useContext(LoaderContext);
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );

  const { isLoading, data } = useGetProfilesQuery(undefined);

  const { show } = useToast();

  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [switchProfile, { isLoading: isSwitchProfileLoading }] =
    useSwitchProfileMutation();
  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();

  const handleSwitchProfile = async (id: number) => {
    try {
      setLoading(true);
      onClose();
      const response = await switchProfile(id).unwrap();
      setLoading(false);
      dispatch(setProfileTokens(response.token));
      dispatch(updateUserRole("child"));
      dispatch(saveProfileId(id));
      navigate("/app/learn", { replace: true });
      console.log(response);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  console.log(data);

  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();

  const {
    isOpen: isManageProfileModalOpen,
    onOpen: onManageProfileOpen,
    onClose: onManageProfileModalClose,
  } = useDisclosure();
  const {
    isOpen: isUpgradeProfileModalOpen,
    onOpen: onUpgradeProfileOpen,
    onClose: onUpgradeProfileModalClose,
  } = useDisclosure();

  const {
    isOpen: isProfileLimitModalOpen,
    onOpen: onProfileLimitOpen,
    onClose: onProfileLimitClose,
  } = useDisclosure();

  const handleUserLogout = () => {
    onOpen();
  };
  const handleLogoutUser = async () => {
    const submitValues: { hash: string; meta: MetaData } = {
      hash: `${token}`,
      meta: {
        osname: osName,
        deviceId: "sdsd",
        ipaddress: "127.0.0",
        brand: "mac",
      },
    };
    try {
      const response = await logout(submitValues).unwrap();
      console.log(response);
      onCloseModal();
      dispatch(handleLogout());
    } catch (e) {
      console.log(e);
      onCloseModal();
      dispatch(handleLogout());
    }
  };

  const unpaidProfiles = data?.filter(
    (item) => item.status === ("pending" || "inactive")
  );

  console.log(unpaidProfiles);

  const handleCreateProfile = () => {
    if (!!data) {
      if (data?.length < 3) {
        navigate("create-profile");
      } else {
        onProfileLimitOpen();
      }
    }
  };

  return (
    <>
      <div className={styles.ManageProfile}>
        <PageHeader handleGoBack={onClose} pageTitle="Set Up Profile" />
        <div className={styles.ProfilesWrapper}>
          {data?.map((profile, index) => (
            <Tooltip
              key={profile.id}
              label={profile.firstName + " " + profile.lastName}
            >
              <button onClick={() => handleSwitchProfile(profile.id)}>
                <img
                  src={profile?.avatar?.url}
                  style={{
                    borderRadius: "22px",
                    height: "76px",
                    width: "76px",
                    border: `2px solid ${colors.primary}`,
                    objectFit: "cover",
                  }}
                />
              </button>
            </Tooltip>
          ))}
          {isLoading && (
            <>
              <ProfileAvatar image="" isLoading />
              <ProfileAvatar image="" isLoading />
              <ProfileAvatar image="" isLoading />
            </>
          )}
          <button onClick={handleCreateProfile}>
            <AddProfile />
          </button>
        </div>
        <div className={styles.Container}>
          <Text fontWeight="light" variant="heading 4">
            Manage Profile
          </Text>
          <div className={styles.Wrapper}>
            <ProfileItem
              onClick={onUpgradeProfileOpen}
              icon={<ProfilePayForSubscriptionIcon />}
              title="Pay For Subscription"
            />
            {token && (
              <ProfileItem
                icon={<ProfileAnalysisIcon />}
                title="Learning Analysis"
                onClick={() => navigate("analytics")}
              />
            )}
            <ProfileItem
              icon={<ProfileAccountIcon />}
              title="Manage Profiles"
              onClick={onManageProfileOpen}
            />
            <ProfileItem
              icon={<ProfilePrivacyIcon />}
              title="Change Password"
              onClick={() => navigate("change-password")}
            />
            <ProfileItem
              icon={<ProfileShareIcon />}
              onClick={() => navigate("share")}
              title="Share"
            />
            <ProfileItem
              onClick={() => navigate("/help")}
              icon={<ProfileHelpIcon />}
              title="Help"
            />
            {/* <ProfileItem icon={<ProfileDevicesIcon />} title="Manage Devices" /> */}
            {/* <ProfileItem
              icon={<ProfileTermsAndConditionsIcon />}
              title="Terms and Conditions"
            /> */}

            <ProfileItem
              icon={<ProfileLogoutIcon />}
              title="Log out"
              onClick={handleUserLogout}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent borderRadius="20px" marginLeft="20px" marginRight="20px">
          <div className={styles.Logout}>
            <img src={logoutImage} alt="" />
            <Text variant="heading 5" style={{ color: colors.error }}>
              Are you sure you want to sign out from this device?
            </Text>
            <div className={styles.LogoutButtons}>
              <Button
                onClick={handleLogoutUser}
                isLoading={isLoading}
                variant="outlined"
                buttonStyles={{ marginBottom: "13px" }}
              >
                Sign out
              </Button>
              <button id={styles.TextButton} onClick={onClose}>
                No, cancel
              </button>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isManageProfileModalOpen}
        onClose={onManageProfileModalClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ManageProfileModal}>
            <Text>Manage Profiles</Text>
            <div className={styles.ProfilesAvatarsList}>
              {data && data.length > 0 ? (
                data?.map((profile, index) => (
                  <div
                    key={profile.id}
                    className={styles.ManageProfileModalItem}
                  >
                    <button
                      onClick={() => {
                        onManageProfileModalClose();
                        onClose();
                        navigate(`/app/guardian/manage-profiles/${profile.id}`);
                      }}
                      className={styles.ManageProfileItemAvatarWrapper}
                    >
                      <EditIcon style={{ position: "absolute" }} />
                      <ProfileAvatar
                        variant={index === 1 ? "curve" : "default"}
                        image={`${profile.avatar?.url}`}
                        isEdit
                        isLoading={isLoading}
                        index={index}
                      />
                    </button>
                    <Text
                      style={{
                        fontSize: 16,
                        width: "100px",
                        textAlign: "center",
                      }}
                    >{`${profile.firstName} ${profile.lastName}`}</Text>
                  </div>
                ))
              ) : (
                <div
                  style={{ flexDirection: "column" }}
                  className={styles.EmptyUnpaidProfilesMessage}
                >
                  <Text style={{ textAlign: "center" }}>
                    You have no profiles to manage.
                  </Text>
                  <Button
                    onClick={() => navigate("/app/guardian/create-profile")}
                    buttonStyles={{ marginTop: "20px" }}
                  >
                    Create Profile
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isUpgradeProfileModalOpen}
        onClose={onUpgradeProfileModalClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ManageProfileModal}>
            <Text>Pay For Subscription</Text>
            <div
              style={{ justifyContent: "space-between" }}
              className={styles.ProfilesContainer}
            >
              <div className={styles.ProfilesAvatarsList}>
                {unpaidProfiles && unpaidProfiles?.length > 0 ? (
                  unpaidProfiles.map((profile, index) => (
                    <div
                      key={profile.id}
                      className={styles.ManageProfileModalItem}
                    >
                      <button
                        onClick={() => {
                          onUpgradeProfileModalClose();
                          onClose();
                          navigate(
                            `/app/guardian/manage-profiles/${profile.id}`
                          );
                        }}
                        className={styles.ManageProfileItemAvatarWrapper}
                      >
                        <CreditCardIcon style={{ position: "absolute" }} />
                        <ProfileAvatar
                          variant={index === 1 ? "curve" : "default"}
                          image={`${profile.avatar?.url}`}
                          isEdit
                          isLoading={isLoading}
                          index={index}
                        />
                      </button>
                      <Text
                        style={{
                          fontSize: 16,
                          textAlign: "center",
                          width: "100px",
                        }}
                      >{`${profile.firstName} ${profile.lastName}`}</Text>
                    </div>
                  ))
                ) : (
                  <div className={styles.EmptyUnpaidProfilesMessage}>
                    <Text style={{ textAlign: "center" }}>
                      You have no profiles that need to be upgraded
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        disabled={!data}
                        onClick={handleCreateProfile}
                        variant="white"
                        buttonStyles={{
                          width: "200px",
                          fontSize: isMobileBreakpoint ? "10px" : "20px",
                        }}
                      >
                        Create Profile
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <Text
                color={colors.info}
                variant="caption"
                style={{ textAlign: "center" }}
              >
                Payment can only be made after creating a profile for the
                student. You can create a maximum of three student profiles.
              </Text>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isProfileLimitModalOpen} onClose={onProfileLimitClose}>
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ProfileLimitModal}>
            <Text style={{ textAlign: "center" }}>
              Your profile creation limit has been reached.
            </Text>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ManageProfile;
