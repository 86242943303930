import React from "react";
import styles from "./ProfileItem.module.scss";
import Text from "../../atoms/Text";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrowRight.svg";
import { colors } from "../../../../sdk/colors";

function ProfileItem({
  icon,
  title,
  onClick,
}: {
  icon: JSX.Element;
  title: string;
  onClick?: () => void;
}) {
  return (
    <button onClick={onClick} className={styles.ProfileItem}>
      <div className={styles.Wrapper}>
        {icon}
        <Text style={{ marginLeft: "20px", color: colors.gray }}>{title}</Text>
      </div>
      <ArrowRight />
    </button>
  );
}

export default ProfileItem;
