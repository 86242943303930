import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import styles from "./Lessons.module.scss";
import MyLessonsCard from "../../../components/UI/molecules/MyLessonsCard";
import PageHeader from "../../../components/UI/molecules/PageHeader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/whiteDownloadIcon.svg";
import LearningGif from "../../../assets/images/subject.gif";
import { useGetAllSubjectsQuery } from "../../../redux/features/lessons/lessonsApiSlice";
import { Helmet } from "react-helmet";

function Lessons() {
  const navigate = useNavigate();
  const [controlledSwiper, setControlledSwiper] = useState<any>(null);

  useEffect(() => {
    if (controlledSwiper === 0) {
      backgroundColorValue = "#59E1BF";
    } else if (controlledSwiper === 1) {
      backgroundColorValue = "#FF8282";
    } else if (controlledSwiper === 2) {
      backgroundColorValue = "#9747FF";
    }
  }, [controlledSwiper]);

  let backgroundColorValue: string = useMemo(
    () =>
      controlledSwiper === 2
        ? "#9747FF"
        : controlledSwiper === 1
        ? "#FF8282"
        : "#59E1BF",
    [controlledSwiper]
  );

  const { isLoading, data } = useGetAllSubjectsQuery(undefined);

  console.log(data);

  return (
    <div
      style={{ backgroundColor: backgroundColorValue }}
      className={styles.Lessons}
    >
      <Helmet>
        <title>Lessons - UBELA</title>
      </Helmet>
      <div className={styles.Header}>
        <PageHeader
          textColor="#fff"
          pageTitle="My Lessons"
          handleGoBack={() => navigate(-1)}
          rightComponent={
            <DownloadIcon
              onClick={() => navigate("/app/learn/downloads")}
              style={{ cursor: "pointer" }}
            />
          }
        />
      </div>
      <img src={LearningGif} alt="" id={styles.LearningGif} />
      <Swiper
        id={styles.SwiperWrapper}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        className="mySwiper"
        onSwiper={(swiper) => {
          setControlledSwiper(swiper.activeIndex);
          swiper.pagination.bullets.forEach((bullet) => {
            bullet.addEventListener('click', () => {
              setControlledSwiper(swiper.activeIndex);
            });
          });
        }}
        onSlideChange={(swiper) => setControlledSwiper(swiper.activeIndex)}
      >
        {data?.data.map((subject) => (
          <SwiperSlide key={subject.id} id={styles.SwiperItem}>
            <MyLessonsCard subject={subject} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Lessons;
