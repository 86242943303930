import React, { useEffect } from "react";
import styles from "./Home.module.scss";
import UserHeader from "../../../components/UI/molecules/UserHeader";
import { ReactComponent as WelcomeAdornment1 } from "../../../assets/icons/welcomeAdornment1.svg";
import { ReactComponent as WelcomeAdornment2 } from "../../../assets/icons/welcomeAdornment2.svg";
import { ReactComponent as WelcomeAdornment3 } from "../../../assets/icons/welcomeAdornment3.svg";
import { ReactComponent as WelcomeAdornment4 } from "../../../assets/icons/welcomeAdornment4.svg";
import WelcomeImage from "../../../assets/images/welcomeImage.svg";
import Text from "../../../components/UI/atoms/Text";
import { colors } from "../../../sdk/colors";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import YourLessonLibrary from "../../../components/organisms/YourLessonLibrary";
import { Skeleton, SkeletonText } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import LessonItem from "../../../components/UI/molecules/LessonItem";
import { updateUserRole } from "../../../redux/features/auth/authSlice";
import { ReactComponent as EmptyActivityImage } from "../../../assets/images/EmptyActivityImage.svg";
import { mobileBreakPoint, subjects } from "../../../constants/values";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useGetLessonsActivityQuery } from "../../../redux/features/analytics/analyticsApiSlice";
import {
  mergeAndFilterArrays,
  mergeAndRemoveDuplicates,
} from "../../../utils/removeCopies";
import Tag from "../../../components/UI/atoms/Tag";

function Home() {
  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;
  const profileId = useAppSelector(
    (state: RootState) => state.auth.activeProfileId
  );

  const { data: user, isLoading } = useGetUserQuery(undefined, {
    refetchOnReconnect: true,
  });

  const { data: analytics } = useGetLessonsActivityQuery(
    Number(user?.id || profileId),
    { refetchOnMountOrArgChange: true }
  );

  console.log(analytics, "analytics");

  const dispatch = useAppDispatch();

  const unfinishedLessons = useAppSelector(
    (state: RootState) => state.lessons.unfinishedLessons
  );

  const finishedLessons = useAppSelector(
    (state: RootState) => state.lessons.finishedLessons
  );

  const mergedUnfinishedLessons =
    analytics && analytics?.data.length > 0
      ? mergeAndRemoveDuplicates(analytics.data, unfinishedLessons)
      : [];

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(updateUserRole("child"));
    console.log("mounting guardian");
  }, []);

  const continueLearningLessons = unfinishedLessons.filter(
    (lessons) => lessons.profileId === (user?.id || profileId)
  );

  const finishedContinueLearningLessons = finishedLessons.filter(
    (lessons) => lessons.profileId === (user?.id || profileId)
  );

  console.log(continueLearningLessons, "cll");

  return (
    <div className={styles.Home}>
      <Helmet>
        <title>Home - UBELA</title>
      </Helmet>
      <UserHeader onClickAvatar={() => navigate("/app")} />
      <div className={styles.Container}>
        <Skeleton
          startColor={colors.grey5}
          endColor={colors.grey4}
          borderRadius="20px"
          isLoaded={!isLoading}
        >
          <div className={styles.Welcome}>
            <WelcomeAdornment1 id={styles.welcomeAdorn1} />
            <WelcomeAdornment2 id={styles.welcomeAdorn2} />
            <WelcomeAdornment3 id={styles.welcomeAdorn3} />
            <WelcomeAdornment4 id={styles.welcomeAdorn4} />
            <div className={styles.WelcomeContent}>
              <Text style={{ color: colors.grey2 }} variant="heading 4">
                Hi {user?.firstName}
              </Text>
              <Text
                style={{
                  fontFamily: "ComicSans",
                  fontSize: isMobileBreakpoint ? "22px" : "28px",
                  lineHeight: isMobileBreakpoint ? "30px" : "36px",
                  marginTop: isMobileBreakpoint ? 0 : 10,
                }}
              >
                What Do You Want To Learn Today?
              </Text>
            </div>
            <img src={WelcomeImage} />
          </div>
        </Skeleton>
        <YourLessonLibrary />
        <div className={styles.ContinueLearning}>
          <SkeletonText
            startColor={colors.grey5}
            endColor={colors.grey4}
            noOfLines={1}
            width={"100%"}
            isLoaded={!isLoading}
            style={{ marginBottom: "22px" }}
          >
            <div className={styles.Header}>
              <Text variant="heading 4" fontWeight="light">
                Continue Learning
              </Text>
              {mergeAndFilterArrays(
                analytics?.data,
                continueLearningLessons,
                finishedContinueLearningLessons
              ).length > 3 && (
                <Tag onClick={() => navigate("continue-learning")}>See all</Tag>
              )}
            </div>
          </SkeletonText>
          {!isLoading &&
          mergeAndFilterArrays(
            analytics?.data,
            continueLearningLessons,
            finishedContinueLearningLessons
          ).length > 0 ? (
            <div className={styles.LessonsWrapper}>
              {mergeAndFilterArrays(
                analytics?.data,
                continueLearningLessons,
                finishedContinueLearningLessons
              )
                .slice(0, 3)
                .map((lesson, index) => (
                  <LessonItem
                    progress={lesson.progress}
                    key={lesson.id + index}
                    index={index}
                    lesson={lesson}
                    subjectId={lesson.subjectId}
                    color={
                      subjects.find(
                        (subject) => subject.id === lesson.subjectId
                      )?.color
                    }
                  />
                ))}
            </div>
          ) : (
            !isLoading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px 0px",
                  width: "100%",
                }}
              >
                <EmptyActivityImage />
                <Text>You can access unfinished lessons here </Text>
              </div>
            )
          )}
          {isLoading && (
            <>
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
