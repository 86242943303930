import { Profile } from "../models/profile";
import { SubPlans } from "../models/subscription";

export class ProfileHelper {
  static getProfileSubscriptionPlan(subStatus: SubPlans): string {
    let subscriptionPlan: string = "Free Account";
    if (subStatus) {
      if (subStatus === "3 months") {
        subscriptionPlan = "3 Months Plan";
      } else if (subStatus === "6 months") {
        subscriptionPlan = "6 Months Plan";
      }
    } else {
      subscriptionPlan = "Free Account";
    }
    return subscriptionPlan;
  }

  static getFullName(profile: Profile | undefined): string {
    return !!profile ? `${profile.firstName} ${profile.lastName}` : "";
  }
}
