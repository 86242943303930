import React from "react";
import styles from "./Championship.module.scss";
import UserHeader from "../../../components/UI/molecules/UserHeader";
import Text from "../../../components/UI/atoms/Text";
import welcomeImage from "../../../assets/images/championshipWelcomeImage.webp";
import Button from "../../../components/UI/atoms/Button";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import {
  useGetChampionshipsQuery,
  useGetLeaderBoardQuery,
} from "../../../redux/features/championship/championshipApiSlice";
import LeaderBoard from "../../../components/organisms/LeaderBoard";
import { useNavigate } from "react-router-dom";
import { championshipHelper } from "../../../utils/championshipHelper";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { Helmet } from "react-helmet";
import { mobileBreakPoint } from "../../../constants/values";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function Championship() {
  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;
  const navigate = useNavigate();
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  const { data: user } = useGetUserQuery(`${token}`);
  const { data: championshipsData } = useGetChampionshipsQuery(undefined);
  const isActive = championshipHelper.getActiveState(
    Number(user?.grade),
    championshipsData?.data
  );
  console.log(isActive, championshipsData);
  return (
    <div className={styles.Championship}>
      <Helmet>
        <title>Championship - UBELA</title>
      </Helmet>
      <UserHeader onClickAvatar={() => navigate("/app")} />
      <div className={styles.Container}>
        <div className={styles.Welcome}>
          <div className={styles.Content}>
            <Text
              style={{ marginBottom: "10px" }}
              variant={isMobileBreakpoint ? "heading 4" : "heading 2"}
            >
              Welcome to UBELA championship!
            </Text>
            <Text
              variant={isMobileBreakpoint ? "body" : "heading 5"}
              style={{
                lineHeight: "normal",
                width: isMobileBreakpoint ? "100%" : "70%",
              }}
            >
              Explore a never-ending collection of fun and educational
              questions.
            </Text>
          </div>
          <img src={welcomeImage} alt="Welcome" />
        </div>
        <Button
          disabled={!isActive}
          onClick={() => navigate("quest")}
          buttonStyles={{ marginBottom: "60px" }}
        >
          Begin Quest
        </Button>
        <LeaderBoard />
      </div>
    </div>
  );
}

export default Championship;
