import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styles from "./EditProfile.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import {
  useChangeGradeMutation,
  useDeleteProfileMutation,
  useEditProfileMutation,
  useGetProfilesQuery,
  useUpdateProfileAvatarMutation,
} from "../../redux/features/profile/profileApiSlice";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ProfileAvatar from "../../components/UI/atoms/ProfileAvatar";
import { ReactComponent as EditIcon } from "../../assets/icons/manageProfileEditIcon.svg";
import Text from "../../components/UI/atoms/Text";
import { ProfileHelper } from "../../utils/profileHelper";
import { colors } from "../../sdk/colors";
import { useUploadImageMutation } from "../../redux/features/user/userApiSlice";
import Input from "../../components/UI/atoms/Input";
import { UpdateProfileAvatarBody } from "../../models/profile";
import Button, {
  NextButton,
  SmallNextButton,
} from "../../components/UI/atoms/Button";
import { useToast } from "../../context/toast.context";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import logoutImage from "../../assets/images/signOutImage.svg";
import welcomeImage from "../../assets/images/welcomeImage.svg";
import changeGradeImage from "../../assets/images/changeGradeImage.svg";
import { MetaData } from "../../models/auth";
import { grades, subscriptionPlans } from "../../constants/values";
import Star from "../../assets/icons/Star";
import { ReactComponent as MenuDown } from "../../assets/icons/menuDrown.svg";
import { SubPlansTypes } from "../../models/subscription";
import Tag from "../../components/UI/atoms/Tag";
import SubscriptionPlan from "../../components/organisms/SubscriptionPlan";
import { useGetPlansQuery } from "../../redux/features/subscription/subscriptionApiSlice";
import { ErrorResponse } from "../../models/utilityTypes";
import { ReactComponent as ChangeExhaustedImage } from "../../assets/images/restrictAccessImage.svg";
import LoaderContext from "../../context/loader.context";

function EditProfile() {
  const [uploadImageAsync, { isLoading: isUploadImageLoading }] =
    useUploadImageMutation();

  const { data: plans } = useGetPlansQuery(undefined);

  const [changeGrade, { isLoading: isChangeGradeLoading }] =
    useChangeGradeMutation();

  const [action, setAction] = useState<"edit" | "default">("default");

  const navigate = useNavigate();

  const { show } = useToast();

  const { setLoader: setLoading } = useContext(LoaderContext);

  const [updateAvatar, { isLoading: isUpdateAvatarLoading }] =
    useUpdateProfileAvatarMutation();

  const [deleteProfile, { isLoading: isDeleteLoading }] =
    useDeleteProfileMutation();

  const [editProfile, { isLoading: isEditProfileLoading }] =
    useEditProfileMutation();

  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");

  const [gradeValue, setGradeValue] = useState<
    { value: number; label: string } | undefined
  >();
  const [plan, setPlan] = useState<SubPlansTypes>("free");

  const { profileId } = useParams<{ profileId: string }>();
  console.log(profileId);
  const {
    data: profiles,
    isLoading,
    isFetching,
  } = useGetProfilesQuery(undefined);
  const profile = profiles?.find((profile) => profile.id === Number(profileId));
  console.log(profile);

  useEffect(() => {
    setGradeValue(grades.find((item) => item.value === profile?.grade));
  }, [profiles]);

  const isActive: boolean = useMemo(
    () => profile?.status === "active",
    [profiles]
  );

  const initialValues: { firstName: string; lastName: string } = {
    firstName: useMemo(() => profile?.firstName || "", [profiles]),
    lastName: useMemo(() => profile?.lastName || "", [profiles]),
  };

  const onSubmit = async (values: { firstName: string; lastName: string }) => {
    const submitValues: {
      id: string;
      body: { firstName: string; lastName: string };
    } = {
      body: { firstName: values.firstName, lastName: values.lastName },
      id: profileId || "",
    };
    console.log(submitValues);
    try {
      const response = await editProfile(submitValues).unwrap();
      show({ message: `${response.message}`, variant: "success" });
      console.log(response);
    } catch (e: any) {
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${e?.error || error.data.message}`, variant: "error" });
    }
  };

  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const clickInput = () => {
    if (fileInputRef.current === null) return;
    fileInputRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e?.target?.files?.[0];
    if (!file) return;
    setImage(file);
  };

  const handleUploadImage = async () => {
    const formData = new FormData();
    if (image) {
      formData.append("image", image);
    }
    try {
      setLoading(true);
      const response = await updateAvatar({
        body: formData,
        profileId: Number(profileId),
      }).unwrap();
      setLoading(false);
      show({
        message: "Profile image updated successfully.",
        variant: "success",
      });
      console.log(response);
      return response;
    } catch (e) {
      setLoading(false);
      show({
        message:
          (e as ErrorResponse)?.data?.message ||
          (e as ErrorResponse)?.data?.error ||
          "Something went wrong",
        variant: "error",
      });
      console.log(e);
      return e;
    }
  };

  // const firebaseConfig: FirebaseOptions = {
  //   apiKey: "AIzaSyATDKvAqg1uwey4tqmCytToiTU-uqnVfzA",
  //   authDomain: "ubela1.firebaseapp.com",
  //   databaseURL: "https://ubela1-default-rtdb.firebaseio.com",
  //   projectId: "ubela1",
  //   storageBucket: "ubela1.appspot.com",
  //   messagingSenderId: "1033769205858",
  //   appId: "1:1033769205858:web:66552c69369adc44e8113b",
  //   measurementId: "G-LFC7BSMJS4",
  // };

  // const handleUpdateAvatar = async () => {
  //   try {
  //     const app = initializeApp(firebaseConfig);
  //     const storage = getStorage();

  //     const uri = image ? URL.createObjectURL(image) : "";

  //     const fetchResponse = await fetch(
  //       image ? URL.createObjectURL(image) : ""
  //     );
  //     const theBlob = await fetchResponse.blob();

  //     const blob: any = await new Promise((resolve, reject) => {
  //       const xhr = new XMLHttpRequest();
  //       xhr.onload = function () {
  //         resolve(xhr.response);
  //       };
  //       xhr.onerror = function (e) {
  //         console.log(e);
  //         reject(new TypeError("Network request failed"));
  //       };
  //       xhr.responseType = "blob";
  //       xhr.open("GET", uri, true);
  //       xhr.send(null);
  //     });

  //     const fileRef = ref(getStorage(app), `users/${image?.name}`);

  //     const metadata = {
  //       contentType: blob.type,
  //     };

  //     const imageRef = ref(storage, `users/${image?.name}`);
  //     uploadBytes(imageRef, blob, metadata).then((snapshot) => {
  //       console.log(snapshot, "snapshot");
  //       getDownloadURL(imageRef).then((d) => {
  //         handleUploadImage({
  //           originalname: snapshot.metadata.name,
  //           size_in_kb: snapshot.metadata.size,
  //           mimetype: snapshot?.metadata?.contentType || "",
  //           key: snapshot.metadata.generation,
  //           url: d,
  //         });
  //       });
  //     });
  //     // const response = await updateAvatar().unwrap();
  //     // console.log(response);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const uploadImage = async () => {
  //   const formData = new FormData();
  //   if (image) {
  //     formData.append("image", image);
  //   }
  //   try {
  //     const response = await uploadImageAsync(formData).unwrap();
  //     console.log(response);
  //     const imageData = response.data;
  //     handleUpdateAvatar({
  //       url: imageData.url,
  //       mimetype: imageData.type,
  //       originalname: imageData.info.originalname,
  //       size_in_kb: imageData.sizeInKb,
  //       key: imageData.key,
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    if (image && !imageUrl) {
      handleUploadImage();
    }
  }, [image]);

  const {
    isOpen: isGradeOpen,
    onOpen: onGradeOpen,
    onClose: onGradeClose,
  } = useDisclosure();

  const {
    isOpen: isPlanOpen,
    onOpen: onPlanOpen,
    onClose: onPlanClose,
  } = useDisclosure();

  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();

  const {
    isOpen: isMoveClassModalOpen,
    onOpen: onMoveClassModalOpen,
    onClose: onMoveClassModalClose,
  } = useDisclosure();

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isChangeExhaustedModalOpen,
    onOpen: onChangeExhaustedOpen,
    onClose: onChangeExhaustedClose,
  } = useDisclosure();

  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();

  const handleDeleteUser = async () => {
    try {
      const response = await deleteProfile(`${profileId}`).unwrap();
      console.log(response);
      onCloseModal();
      navigate("/app", { replace: true });
    } catch (e) {
      console.log(e);
      onCloseModal();
    }
  };

  const handleChangeGrade = async () => {
    const submitValues: { profileId: number; grade: number } = {
      profileId: Number(profileId),
      grade: Number(gradeValue?.value),
    };
    try {
      const response = await changeGrade(submitValues).unwrap();
      console.log(response);
      show({
        message: "Class has been successfully moved forward",
        variant: "success",
      });
      localStorage.removeItem("unfinishedLessons");
      onMoveClassModalClose();
    } catch (e: any) {
      const error =
        (e as ErrorResponse)?.data?.message ||
        (e as ErrorResponse)?.data?.error;
      console.log(e);
      onMoveClassModalClose();
      if ((e as ErrorResponse)?.data?.error === "grade.change.exhausted") {
        onChangeExhaustedOpen();
      } else {
        show({ message: error, variant: "error" });
      }
    }
  };

  const handleSetToEdit = () => {
    setAction("edit");
    onEditModalOpen();
  };
  return (
    <>
      <div className={styles.EditProfile}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty,
          }) => (
            <Form style={{ width: "100%" }}>
              <PageHeader
                pageTitle="Manage Profile"
                rightComponent={
                  action === "default" ? (
                    <Tag onClick={handleSetToEdit}>Click To Edit</Tag>
                  ) : (
                    <SmallNextButton
                      isLoading={isEditProfileLoading}
                      disabled={!dirty}
                      onClick={handleSubmit}
                    >
                      Edit
                    </SmallNextButton>
                  )
                }
              />
              <div className={styles.Container}>
                <div className={styles.AvatarWrapper}>
                  <button
                    onClick={clickInput}
                    className={styles.EditAvatar}
                    type="button"
                  >
                    <input
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      accept="image/jpeg, image/png"
                      type="file"
                      name="profile-image"
                      style={{ display: "none" }}
                    />
                    <img
                      src={profile?.avatar?.url}
                      style={{
                        borderRadius: "22px",
                        height: "76px",
                        width: "76px",
                        border: `2px solid ${colors.primary}`,
                        objectFit: "cover",
                        backgroundColor: "#fff",
                      }}
                    />
                    <EditIcon
                      style={{ position: "absolute", right: -26, top: 20 }}
                    />
                  </button>
                  <Text fontWeight="medium">
                    {ProfileHelper.getFullName(profile)}
                  </Text>
                  <Text
                    style={{ marginTop: "6px", textTransform: "capitalize" }}
                    color={colors.primary}
                    variant="heading 6"
                  >
                    {profile?.status}
                  </Text>
                </div>

                <div className={styles.Form}>
                  <div className={styles.Row}>
                    <div className={styles.InputWrapper}>
                      <Input
                        label="First Name"
                        disabled={action === "default"}
                        name="firstName"
                        id="firstName"
                        value={values.firstName}
                        error={!!(touched.firstName && errors.firstName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.firstName && errors.firstName && (
                        <Text variant="caption" color={colors.error}>
                          {errors.firstName}
                        </Text>
                      )}
                    </div>
                    <div className={styles.InputWrapper}>
                      <Input
                        label="Last Name"
                        disabled={action === "default"}
                        name="lastName"
                        id="lastName"
                        value={values.lastName}
                        error={!!(touched.lastName && errors.lastName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.lastName && errors.lastName && (
                        <Text variant="caption" color={colors.error}>
                          {errors.lastName}
                        </Text>
                      )}
                    </div>
                  </div>
                  <div className={styles.InputWrapper}>
                    <Input
                      readOnly
                      disabled={!isActive || action === "edit"}
                      label="Grade"
                      value={gradeValue?.label || ""}
                      onClick={onGradeOpen}
                      inputAdornment={<MenuDown />}
                    />
                  </div>
                  {!isActive && (
                    <div className={styles.InputWrapper}>
                      <Input
                        disabled={action === "edit"}
                        readOnly
                        label="Plan"
                        onClick={onPlanOpen}
                        inputAdornment={<MenuDown />}
                        value={plan}
                      />
                    </div>
                  )}
                  {isActive ? (
                    <Tooltip
                      label={
                        action === "edit"
                          ? "You are on edit mode"
                          : !!profile &&
                            Number(gradeValue?.value) <=
                              Number(profile?.grade) &&
                            "Select a higher grade to move forward to"
                      }
                    >
                      <div>
                        <Button
                          disabled={
                            action === "edit" ||
                            (!!profile &&
                              Number(gradeValue?.value) <=
                                Number(profile?.grade))
                          }
                          onClick={onMoveClassModalOpen}
                          buttonStyles={{
                            marginBottom: "30px",
                            marginTop: "50px",
                          }}
                        >
                          Move Class Forward
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      label={
                        action === "edit"
                          ? "You are on edit mode"
                          : plan === "free"
                          ? "Select a paid plan"
                          : undefined
                      }
                    >
                      <div>
                        <Button
                          disabled={action === "edit" || plan === "free"}
                          buttonStyles={{
                            marginBottom: "30px",
                            marginTop: "50px",
                          }}
                          onClick={onDrawerOpen}
                        >
                          Pay For Subscription
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  <Button
                    outlineColor={colors.error}
                    textColor={colors.error}
                    variant="outlined"
                    onClick={onOpen}
                  >
                    Delete This Profile
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent borderRadius="20px" marginLeft="20px" marginRight="20px">
          <div className={styles.DeleteProfile}>
            <img src={logoutImage} alt="" />
            <Text variant="heading 5" style={{ color: colors.error }}>
              Are you sure you want to delete this profile?
            </Text>
            <div className={styles.Buttons}>
              <Button
                onClick={handleDeleteUser}
                isLoading={isDeleteLoading}
                variant="outlined"
                buttonStyles={{ marginBottom: "13px" }}
              >
                Delete
              </Button>
              <button id={styles.TextButton} onClick={onCloseModal}>
                No, cancel
              </button>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isMoveClassModalOpen} onClose={onMoveClassModalClose}>
        <ModalOverlay />
        <ModalContent borderRadius="20px" marginLeft="20px" marginRight="20px">
          <div className={styles.DeleteProfile}>
            <img src={changeGradeImage} alt="" />
            <Text
              variant="heading 5"
              style={{ color: colors.primary, textAlign: "center" }}
            >
              Once you change student class, student would not be able to access
              content from previous classes.
            </Text>
            <div className={styles.Buttons}>
              <Button
                onClick={handleChangeGrade}
                isLoading={isChangeGradeLoading}
                variant="white"
                buttonStyles={{ marginBottom: "13px", width: "200px" }}
              >
                Move Class Forward
              </Button>
              <button id={styles.TextButton} onClick={onMoveClassModalClose}>
                No, cancel
              </button>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
        <ModalOverlay />
        <ModalContent borderRadius="20px" marginLeft="20px" marginRight="20px">
          <div className={styles.DeleteProfile}>
            <img src={welcomeImage} alt="" />
            <Text
              variant="heading 4"
              style={{ color: colors.primary, textAlign: "center" }}
            >
              You can now edit this profile!
            </Text>
            {/* <div className={styles.Buttons}>
              <Button
                onClick={handleChangeGrade}
                isLoading={isChangeGradeLoading}
                variant="white"
                buttonStyles={{ marginBottom: "13px" }}
              >
                Move Class Forward
              </Button>
              <button id={styles.TextButton} onClick={onCloseModal}>
                No, cancel
              </button>
            </div> */}
          </div>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isChangeExhaustedModalOpen}
        onClose={onChangeExhaustedClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px" marginLeft="20px" marginRight="20px">
          <div className={styles.DeleteProfile} style={{ padding: "0px 10px" }}>
            <ChangeExhaustedImage width={"70%"} />
            <div className={styles.ChangeExhaustedContentWrapper}>
              <Text style={{ textAlign: "center" }}>
                Your profile change has been exhausted. Renew your subscription
                to change class.
              </Text>
              <div className={styles.Buttons}>
                <Button
                  onClick={() => {
                    onChangeExhaustedClose();
                    onDrawerOpen();
                  }}
                  variant="white"
                  buttonStyles={{ marginBottom: "13px" }}
                >
                  Renew Subscription
                </Button>
                <button id={styles.TextButton} onClick={onChangeExhaustedClose}>
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isGradeOpen} onClose={onGradeClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {grades.map((grade, index) => (
              <button
                onClick={() => {
                  setGradeValue({ value: grade.value, label: grade.label });
                  onGradeClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Star color={grade.color} />
                <Text>{grade.label}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isPlanOpen} onClose={onPlanClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {subscriptionPlans.map((plan, index) => (
              <button
                onClick={() => {
                  setPlan(plan);
                  onPlanClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Text>{plan}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Drawer
        placement="bottom"
        size="full"
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <SubscriptionPlan
            type="subscription-renewal"
            info={{
              firstName: `${profile?.firstName}`,
              lastName: `${profile?.lastName}`,
              grade: gradeValue?.value,
              profileId: Number(profileId),
            }}
            plan={plan}
            onClose={onDrawerClose}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default EditProfile;
