import React, { useContext, useEffect, useMemo } from "react";
import {
  useGetLessonsActivityQuery,
  useGetProfileActivityQuery,
  useGetTopicsActivityQuery,
} from "../../redux/features/analytics/analyticsApiSlice";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import styles from "./Analytics.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import { ReactComponent as FireIcon } from "../../assets/icons/fire.svg";
import { ReactComponent as TargetIcon } from "../../assets/icons/target.svg";
import Text from "../../components/UI/atoms/Text";
import { colors } from "../../sdk/colors";
import {
  useGetNumberOfTopicsQuery,
  useGetTopicsByGradeQuery,
} from "../../redux/features/lessons/lessonsApiSlice";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { Bar, Doughnut } from "react-chartjs-2";
import { ReactComponent as Dropdown } from "../../assets/icons/dropdown.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import { faker } from "@faker-js/faker";
import Tag from "../../components/UI/atoms/Tag";
import {
  saveProfileId,
  setProfileTokens,
  updateUserRole,
} from "../../redux/features/auth/authSlice";
import {
  useGetProfilesQuery,
  useSwitchProfileMutation,
} from "../../redux/features/profile/profileApiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileAvatar from "../../components/UI/atoms/ProfileAvatar";
import LoaderContext from "../../context/loader.context";
import { StringHelper } from "../../utils/stringHelper";
import { useGetLeaderBoardQuery } from "../../redux/features/championship/championshipApiSlice";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title);
function AnalyticItem({
  type,
  value,
  name,
  isGuardian = false,
}: {
  type: "fire" | "normal";
  value: string;
  name: string;
  isGuardian?: boolean;
}) {
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  const { data: user } = useGetUserQuery(undefined);
  const { isLoading, data } = useGetTopicsActivityQuery(Number(user?.id), {
    refetchOnMountOrArgChange: true,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateUserRole("child"));
    return () => {
      if (isGuardian) dispatch(updateUserRole("guardian"));
    };
  }, []);
  return (
    <div className={styles.AnalyticItem}>
      {type === "fire" ? <FireIcon /> : <TargetIcon />}
      <div className={styles.ItemTextWrapper}>
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <Text variant="heading 4" fontWeight="medium">
            {value}
          </Text>
        )}
        <Text variant="caption" fontWeight="light" color={colors.gray}>
          {name}
        </Text>
      </div>
    </div>
  );
}

function Analytics({ isGuardian = false }: { isGuardian?: boolean }) {
  const { data: user } = useGetUserQuery(undefined);
  const { data: profiles } = useGetProfilesQuery(undefined);
  const dispatch = useAppDispatch();
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );

  useEffect(() => {
    dispatch(updateUserRole("child"));
  }, []);

  const { isLoading: isActivity, data: activityData } =
    useGetProfileActivityQuery(Number(user?.id), {
      refetchOnMountOrArgChange: true,
    });

  const { data: englishTopicsNum, isLoading: isEnglishTopicsNumLoading } =
    useGetNumberOfTopicsQuery(
      {
        subjectId: 1,
        grade: !!user
          ? StringHelper.gradeNumberToString(Number(user.grade))
          : "first",
      },
      { skip: !user || !token }
    );
  const { data: mathTopicsNum, isLoading: isMathTopicsNumLoading } =
    useGetNumberOfTopicsQuery(
      {
        subjectId: 2,
        grade: !!user
          ? StringHelper.gradeNumberToString(Number(user.grade))
          : "first",
      },
      { skip: !user || !token }
    );
  const { data: scienceTopicsNum, isLoading: isScienceTopicsNumLoading } =
    useGetNumberOfTopicsQuery(
      {
        subjectId: 3,
        grade: !!user
          ? StringHelper.gradeNumberToString(Number(user.grade))
          : "first",
      },
      { skip: !user || !token }
    );

  console.log(
    activityData,
    "activity",
    englishTopicsNum,
    mathTopicsNum,
    scienceTopicsNum
  );

  const totalTopics =
    Number(englishTopicsNum?.data?.number_of_lessons || 0) +
    Number(mathTopicsNum?.data?.number_of_lessons || 0) +
    Number(scienceTopicsNum?.data?.number_of_lessons || 0);

  const totalLessons =
    Number(englishTopicsNum?.data?.number_of_topics || 0) +
    Number(mathTopicsNum?.data?.number_of_topics || 0) +
    Number(scienceTopicsNum?.data?.number_of_topics || 0);

  console.log(user);
  const chartdata = {
    labels: ["Not started", "Started", "Done"],
    datasets: [
      {
        label: "# of Topics",
        data: [
          totalTopics -
            (Number(activityData?.data.started_lessons) +
              Number(activityData?.data.done_lessons)),
          activityData?.data.started_lessons || 0,
          activityData?.data.done_lessons || 0,
        ],
        backgroundColor: [
          colors.math + "70",
          colors.info + "70",
          colors.success + "70",
        ],
        borderColor: [colors.math, colors.info, colors.success],
        borderWidth: 1,
      },
    ],
  };
  const chartdata1 = {
    labels: ["Not started", "Started", "Done"],
    datasets: [
      {
        label: "# of Topics",
        data: [
          totalTopics -
            (Number(activityData?.data.started_topics) +
              Number(activityData?.data.done_topics)),
          activityData?.data.started_topics || 0,
          activityData?.data.done_topics || 0,
        ],
        backgroundColor: [
          colors.math + "70",
          colors.info + "70",
          colors.success + "70",
        ],
        borderColor: [colors.math, colors.info, colors.success],
        borderWidth: 1,
      },
    ],
  };

  const { isLoading, data } = useGetTopicsActivityQuery(Number(user?.id), {
    refetchOnMountOrArgChange: true,
    skip: !token,
  });

  const { isLoading: isLessonsLoading, data: lessonsAnalytics } =
    useGetLessonsActivityQuery(Number(user?.id), {
      refetchOnMountOrArgChange: true,
      skip: !token,
    });

  const { isLoading: isChampionshipLoading, data: championshipAnalytics } =
    useGetLessonsActivityQuery(Number(user?.id), {
      refetchOnMountOrArgChange: true,
      skip: !token,
    });

  console.log(
    data?.data.started_lessons,
    lessonsAnalytics,
    championshipAnalytics,
    "analytics"
  );

  const location = useLocation();

  const userRole = useAppSelector((state: RootState) => state.auth.userRole);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!token) {
      dispatch(updateUserRole("child"));
      onOpen();
    }
  }, [isOpen, token]);

  const handleSwitchToGuardianFailSafe = () => {
    console.log("fail safe");
    if (user) {
      if (user?.role === "guardian") {
        console.log("fail safe", userRole, user.role);
        dispatch(updateUserRole("child"));
        window.location.reload();
      }
    }
  };

  // console.log(userRole, "dashboard user role");

  useEffect(() => {
    dispatch(updateUserRole("child"));
    handleSwitchToGuardianFailSafe();
  }, [location]);

  const { setLoader: setLoading } = useContext(LoaderContext);

  const [switchProfile, { isLoading: isSwitchProfileLoading }] =
    useSwitchProfileMutation();

  const navigate = useNavigate();

  const handleSwitchProfile = async (id: number) => {
    if (id == user?.id) {
      onClose();
    } else {
      try {
        setLoading(true);
        onClose();
        const response = await switchProfile(id).unwrap();
        setLoading(false);
        dispatch(setProfileTokens(response.token));
        dispatch(saveProfileId(id));
        window.location.reload();
        console.log(response);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  };

  const { isLoading: isLeaderboardLoading, data: leaderboard } =
    useGetLeaderBoardQuery(undefined, { skip: !token });

  console.log(leaderboard);

  const userLeaderBoardData = leaderboard?.data.find(
    (profile) => profile.profile.id === user?.id
  );

  const userLeaderBoardPosition = leaderboard?.data.findIndex(
    (profile) => profile.profile.id === user?.id
  );

  const startedLessons =
    Number(activityData?.data.started_topics || 0) +
    Number(activityData?.data?.done_topics || 0);

  const startedTopics =
    Number(activityData?.data.started_lessons || 0) +
    Number(activityData?.data?.done_lessons || 0);

  return (
    <>
      <div className={styles.Analytics}>
        <PageHeader pageTitle="Learning Analysis" />
        <div className={styles.Container}>
          {isGuardian && (
            <Tag onClick={onOpen} style={{ marginBottom: "30px" }}>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {user?.firstName}
              </Text>
              <Dropdown />
            </Tag>
          )}
          <div className={styles.AvatarWrapper}>
            <div className={styles.Avatar}>
              <img src={user?.avatar?.url} />
            </div>
            <Text
              variant="heading 4"
              style={{ marginBottom: "10px" }}
              fontWeight="light"
            >
              {user?.firstName}
            </Text>
            {userLeaderBoardData?.score ? (
              <>
                <Text
                  color={colors.grey2}
                  variant="heading 5"
                  fontWeight="regular"
                >
                  Overall position:{" "}
                  {Number(Number(userLeaderBoardPosition) + 1) ||
                    "Can't find your position"}
                </Text>
                <Text
                  color={colors.grey2}
                  variant="heading 5"
                  fontWeight="regular"
                >
                  Overall points: {userLeaderBoardData?.id || "0"}
                </Text>
              </>
            ) : (
              <Text
                color={colors.grey2}
                variant="heading 5"
                fontWeight="regular"
              >
                You don't have a score
              </Text>
            )}
          </div>

          <div className={styles.Wrapper}>
            <Text variant="heading 4" fontWeight="light">
              Your Topics Overview
            </Text>
            <div className={styles.ItemsWrapper}>
              <AnalyticItem
                isGuardian={isGuardian}
                type="fire"
                value={`${totalLessons}`}
                name="Total"
              />
              <AnalyticItem
                isGuardian={isGuardian}
                type="normal"
                value={`${startedTopics}`}
                name="Started"
              />
              <AnalyticItem
                isGuardian={isGuardian}
                type="normal"
                value={`${activityData?.data.done_lessons || 0}`}
                name="Done"
              />
            </div>
            {activityData &&
              englishTopicsNum &&
              scienceTopicsNum &&
              mathTopicsNum && (
                <div className={styles.Chart}>
                  <div className={styles.ChartTextWrapper}>
                    <Text variant="heading 4">
                      {(Number(activityData?.data?.done_lessons || 0) /
                        (!!totalTopics ? totalTopics || 0 : startedTopics) ||
                        0) * 100}
                      %
                    </Text>
                    <Text
                      color={colors.grey3}
                      fontWeight="light"
                      style={{ fontSize: "12px" }}
                    >
                      Done
                    </Text>
                  </div>
                  <Doughnut
                    style={{ maxHeight: "300px", maxWidth: "300px" }}
                    data={chartdata}
                  />
                </div>
              )}
          </div>
          <div className={styles.Wrapper}>
            <Text variant="heading 4" fontWeight="light">
              Your Lessons Overview
            </Text>
            <div className={styles.ItemsWrapper}>
              <AnalyticItem
                isGuardian={isGuardian}
                type="fire"
                value={`${totalLessons}`}
                name="Total"
              />
              <AnalyticItem
                isGuardian={isGuardian}
                type="normal"
                value={`${startedLessons}`}
                name="Started"
              />
              <AnalyticItem
                isGuardian={isGuardian}
                type="normal"
                value={`${activityData?.data.done_topics || 0}`}
                name="Done"
              />
            </div>
            {activityData &&
              englishTopicsNum &&
              scienceTopicsNum &&
              mathTopicsNum && (
                <div className={styles.Chart}>
                  <div className={styles.ChartTextWrapper}>
                    <Text variant="heading 4">
                      {(Number(activityData?.data?.done_topics || 0) /
                        (!!totalLessons ? totalLessons || 0 : startedLessons) ||
                        0) * 100}
                      %
                    </Text>
                    <Text
                      color={colors.grey3}
                      fontWeight="light"
                      style={{ fontSize: "12px" }}
                    >
                      Done
                    </Text>
                  </div>
                  <Doughnut
                    style={{ maxHeight: "300px", maxWidth: "300px" }}
                    data={chartdata1}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ManageProfileModal}>
            <Text>
              {!token
                ? "Please choose a profile to proceed with"
                : "Choose Profile"}
            </Text>
            <div className={styles.ProfilesAvatarsList}>
              {profiles?.map((profile, index) => (
                <div key={profile.id} className={styles.ManageProfileModalItem}>
                  <button
                    onClick={() => handleSwitchProfile(profile.id)}
                    className={styles.ManageProfileItemAvatarWrapper}
                  >
                    <ProfileAvatar
                      variant={index === 1 ? "curve" : "default"}
                      image={`${profile?.avatar?.url}`}
                      isEdit
                      isLoading={isLoading}
                      index={index}
                    />
                  </button>
                  <Text
                    style={{ fontSize: 16 }}
                  >{`${profile.firstName} ${profile.lastName}`}</Text>
                </div>
              ))}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Analytics;
