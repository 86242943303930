import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthTokens, LoginResponse, RolePropTypes } from "../../../models/auth";

const token = localStorage.getItem("token");
const refresh = localStorage.getItem("refreshToken");
const profileToken = localStorage.getItem("activeProfileToken");
const storedProfileId = localStorage.getItem("profileId");
const profileRefresh = localStorage.getItem("activeProfileRefreshToken");
const role = localStorage.getItem("role");
const storedReferralCode = localStorage.getItem("referralCode");

export interface AuthState {
  authToken: string | undefined;
  authRefreshToken: string | undefined;
  activeProfileToken: string | undefined;
  activeProfileRefreshToken: string | undefined;
  hasBeenOnboarded: boolean;
  confirmEmailMessage: string;
  resetPasswordCode: string;
  emailToReceiveCode: string;
  userRole: RolePropTypes;
  phoneNumber: string;
  userReferralCode: string;
  activeProfileId: number | undefined;
}

const initialState: AuthState = {
  authToken: token ? JSON.parse(token) : "",
  authRefreshToken: refresh ? JSON.parse(refresh) : "",
  activeProfileToken: profileToken ? JSON.parse(profileToken) : "",
  activeProfileId: storedProfileId ? JSON.parse(storedProfileId) : undefined,
  activeProfileRefreshToken: profileRefresh ? JSON.parse(profileRefresh) : "",
  emailToReceiveCode: "",
  confirmEmailMessage: "",
  hasBeenOnboarded: false,
  resetPasswordCode: "",
  userRole: role ? JSON.parse(role) : "",
  phoneNumber: "",
  userReferralCode: storedReferralCode ? JSON.parse(storedReferralCode) : "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateConfirmEmailMessage: (state, action: PayloadAction<string>) => {
      state.confirmEmailMessage = action.payload;
    },
    updateNumberToReceiveCode: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    handleUserLogin: (state, action: PayloadAction<LoginResponse>) => {
      localStorage.setItem(
        "token",
        JSON.stringify(action.payload.token.access.token)
      );
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(action.payload.token.refresh.token)
      );
      localStorage.setItem("role", JSON.stringify(action.payload.data.role));
      localStorage.setItem(
        "referralCode",
        JSON.stringify(action.payload.data.referralCode)
      );
      state.authToken = action.payload.token.access.token;
      state.authRefreshToken = action.payload.token.refresh.token;
      state.userRole = action.payload.data.role;
      state.userReferralCode = action.payload.data.referralCode;
    },
    handleFinishOnboarding: (state) => {
      state.hasBeenOnboarded = true;
    },
    updateUserRole: (state, action: PayloadAction<RolePropTypes>) => {
      state.userRole = action.payload;
      localStorage.setItem("role", JSON.stringify(action.payload));
    },
    handleLogout: (state) => {
      state.authRefreshToken = undefined;
      state.activeProfileRefreshToken = undefined;
      state.authToken = undefined;
      state.activeProfileToken = undefined;
      localStorage.clear();
    },
    tokenReceived: (state, action: PayloadAction<AuthTokens>) => {
      state.authToken = action.payload.access.token;
      state.authRefreshToken = action.payload.refresh.token;

      localStorage.setItem(
        "token",
        JSON.stringify(action.payload.access.token)
      );
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(action.payload.refresh.token)
      );
    },
    saveProfileId: (state, action: PayloadAction<number>) => {
      state.activeProfileId = action.payload;
      localStorage.setItem(
        "profileId",
        JSON.stringify(action.payload)
      );
    },
    setProfileTokens: (state, action: PayloadAction<AuthTokens>) => {
      state.activeProfileToken = action.payload.access.token;
      state.activeProfileRefreshToken = action.payload.refresh.token;
      localStorage.setItem(
        "activeProfileToken",
        JSON.stringify(action.payload.access.token)
      );
      localStorage.setItem(
        "activeProfileRefreshToken",
        JSON.stringify(action.payload.refresh.token)
      );
    },
    storeResetPasswordCode: (state, action: PayloadAction<string>) => {
      state.resetPasswordCode = action.payload;
    },
  },
});

export const {
  updateConfirmEmailMessage,
  handleUserLogin,
  handleFinishOnboarding,
  handleLogout,
  tokenReceived,
  storeResetPasswordCode,
  updateNumberToReceiveCode,
  updateUserRole,
  setProfileTokens,
  saveProfileId
} = authSlice.actions;

export default authSlice.reducer;
