import React from "react";
import LessonLibraryItem from "../../UI/molecules/LessonLibraryItem";
import styles from "./YourLessonLibrary.module.scss";
import { ReactComponent as TextSquiggle } from "../../../assets/icons/textSquiggle.svg";
import Text from "../../UI/atoms/Text";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import { colors } from "../../../sdk/colors";
import { SkeletonText } from "@chakra-ui/react";
import { subjects } from "../../../constants/values";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";

function YourLessonLibrary() {
  const token = useAppSelector((state: RootState) => state.auth.activeProfileToken);
  const { isLoading, data } = useGetUserQuery(undefined);
  const navigate = useNavigate();
  return (
    <div className={styles.YourLessonLibrary}>
      <div className={styles.Header}>
        <SkeletonText
          startColor={colors.grey5}
          endColor={colors.grey4}
          noOfLines={1}
          isLoaded={!isLoading}
        >
          <Text variant="heading 4" fontWeight="light">
            Your Lesson Library
          </Text>
        </SkeletonText>
        <SkeletonText
          startColor={colors.grey5}
          endColor={colors.grey4}
          noOfLines={1}
          isLoaded={!isLoading}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              right: "15px"
            }}
          >
            <TextSquiggle style={{ position: "absolute" }} />
            <Text
              style={{
                color: colors.primary,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Primary {data?.grade}
            </Text>
          </div>
        </SkeletonText>
      </div>

      <div className={styles.Wrapper}>
        {subjects.map((subject, index) => (
          <LessonLibraryItem
            onClick={() => navigate(`subjects/${subject.id}`)}
            key={index}
            isLoading={isLoading}
            subject={subject.name}
          />
        ))}
      </div>
    </div>
  );
}

export default YourLessonLibrary;
